import React, { useEffect, useState } from "react";

import Header from "@/components/Header/Header";
import Footer from "@/components/Footer/Footer";
import { gotoPage } from "@/utils/helpers/common";
import { IGetAllSettingResponse } from "@/utils/api/companySetting";
import companySettingApi from "@/utils/api/companySetting/companySetting.api";

type MainProps = {
  children: React.ReactNode;
};

const Main = (props: MainProps) => {
  const { children } = props;
  const [isMobile, setIfMobile] = useState<boolean>(false);
  const [companySetting, setCompanySetting] =
    useState<IGetAllSettingResponse | null>(null);

  let getCompanySetting = async () => {
    try {
      let res = await companySettingApi.getAllSetting({
        params: {
          language: "eng",
        },
      });
      setCompanySetting(res.data.params);
    } catch (error) {
      console.log(error);
    }
  };

  let listenResizeWindows = () => {
    setIfMobile(window.innerWidth < 1280);
  };

  useEffect(() => {
    window.addEventListener("resize", listenResizeWindows);
    listenResizeWindows();

    getCompanySetting();
  }, []);

  return (
    <div className="flex flex-col w-full justify-start items-center relative" style={{'overflow-x': 'hidden'}}>
      <Header />
      {!isMobile && (
        <img
          className="w-12 h-auto absolute top-6 left-20 z-30 cursor-pointer"
          src={companySetting?.content_logo}
          alt=""
          onClick={() => {
            gotoPage("/");
          }}
        />
      )}
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Main;
